module.exports = {
  langs: [
    {
      key: "pt",
      flag: "🇧🇷️",
    }, 
    {
      key: "en",
      flag: "🇺🇸️"
    }
  ],
  defaultLangKey: "pt",
};
